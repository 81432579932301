import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { findFormations } from "../services/functions"
import formations from "../services/formations.json"
import { Grid } from "@mui/material"
import DropDownCheck from "components/fields/DropdownCheck"

const Formations = () => {

  const { control, setValue, watch } =useFormContext()

  const campus = watch('lead.Campus__c')
  const academicLevel = watch('lead.AcademicLevel__c')
  const watchFormations = watch('formations')
  const [optionsFormations, setOptionsFormations] = useState([])

  useEffect(() => {
    if (campus !== '' && academicLevel !== '') {
      setValue('formations', [])
      formations.forEach(el => setValue(el.field, false))
      let formationsByCampus = []
      if (campus.includes(',')) {
        formationsByCampus = findFormations('Paris', academicLevel, formations)
      } else {
        formationsByCampus = findFormations(campus, academicLevel, formations)
      }
      setOptionsFormations(formationsByCampus)
      if (formationsByCampus.length === 1) {
        setValue(formationsByCampus[0].field, true)
        setValue('formations', [formationsByCampus[0].value])
      }
    }
  }, [academicLevel, campus])

  useEffect(() => {
    for (const options of formations) {
      setValue(options.field, false)
    }
    if (watchFormations.length > 0) {
      const mapFormations = optionsFormations.filter(el => watchFormations.some(el2 => el.value === el2)).map(el => el.field)
      for (const formation of mapFormations) {
        setValue(formation, true)
      }
    }
  }, [watchFormations])

  return (
    <>
      {optionsFormations.length > 1 && (
        <Grid item xs={12}>
          <DropDownCheck control={control} label={`Programme d'intérêt`} multiple name="formations" options={optionsFormations} required sx={optionsFormations.find(el => el.label === 'Apprentissage') !== undefined ? { '#menu-formations li:last-child': { paddingTop: '30px' } } : {}} />
        </Grid>
      )}
    </>
  )
}

export default Formations
